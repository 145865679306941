<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Procurement</span>
      </div>
    </div>
  </div>
</section>
<section class="section-custom-new3">
  <div class="container">
    <!-- <div class="text-center mb-3">
      <h2 class="title1 text-center mb-5">Procurement</h2>
    </div> -->
    <div class="text-center mb-3">
      <h2 class="title1 text-center mb-4">Plataforma de Viajes Travel</h2>
    </div>
    <div class="section-custom-new3-box-btn">
      <a href="https://travelsecurity.kontroltravel.com/login.aspx" class="section-custom-new3-btn btn-design1"
        target="_blank">Link acceso Plataforma de Viajes</a>
      <div class="row my-4">
        <div class="col-lg-8 mx-auto">
          <div class="mb-3">
            <p>
              Usuario: correo corporativo <br>
              Contraseña temporal de acceso: Viajes24* (debes cambiar esta contraseña temporal al ingresar por primera
              vez
              para
              garantizar la seguridad de tus credenciales).
            </p>
          </div>
        </div>
      </div>
      <a href="https://www.travelsecurity.cl/empresas/tutoriales/abastible"
        class="section-custom-new3-btn btn-design1 mb-4" target="_blank">Link acceso Manual de Usuario y
        Política de Viajes de Abastible</a>
      <div class="row my-4">
        <div class="col-lg-8 mx-auto">
          <div class="mb-0">
            <p>Contraseña de acceso: Travel2024*</p>
            <p>A considerar:</p>
            <p><b>Aprobación de Viajes:</b> una vez planificado el viaje, la aprobación será gestionada por el
              coordinador asignado, quien se
              encargará de validar y asegurar que las solicitudes cumplan con los estándares establecidos.</p>
            <p>
              <b>Anticipación:</b>
            </p>
            <ul style="">
              <li><b>Viajes Nacionales:</b> 10 días de anticipación.</li>
              <li><b>Viajes Internacionales</b> 15 dias de anticipación.</li>
            </ul>
            <p class="mb-0">
              Estas medidas permiten organizar de manera eficiente las reservas, controlar costos y garantizar el
              cumplimiento de las
              políticas internas.
            </p>
          </div>
        </div>
      </div>

    </div>
    <div class="text-center mb-3">
      <h2 class="title1 text-center mb-4">Solicitudes PRO</h2>
    </div>
    <div class="section-custom-new3-box-btn">
      <a href="https://apps.powerapps.com/play/e/01c7b590-0d82-e52a-a783-6f905aa891e9/a/c1cdf20c-65d2-4e4d-a5c0-f69ab0fe9092?tenantId=13aff87e-8ecf-406b-926f-b1c5df477126&hint=eec41453-dfe5-49cd-9dc3-722605c69a94&sourcetime=1706188316074&source=portal"
        class="section-custom-new3-btn btn-design1" target="_blank">Link acceso Power Apps</a>
    </div>
    <!-- <div class="text-center mb-3" *ngIf="documentos2.length > 0">
      <h2 class="title1 text-center mb-4">Paso a paso Ticketera Procurement</h2>
    </div>
    <div class="row mb-5" *ngIf="documentos2.length > 0">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos2; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <div class="text-center mb-3">
      <h2 class="title1 text-center mb-4">Formularios Modulo MM</h2>
    </div>
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <div class="content-documents-custom-new3 mb-5">
          <a href="{{document.link}}" class="item-document-custom-new3" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0" *ngFor="let document of documentos1; let i = index">
            <h3 class="title-document-custom-new3" [innerHTML]="document.titulo"></h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>