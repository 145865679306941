// Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import * as $ from 'jquery';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgImageSliderModule } from 'ng-image-slider';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ToastrModule } from 'ngx-toastr';

import { MentionModule } from 'angular-mentions';

// Layouts
import { BaseLayout, InternLayout } from '@layouts';
// Modules 
import { HeaderComponent, HeaderNewComponent, FooterComponent, SidebarComponent } from '@modules';
// Custom Components
import { LoadingComponent } from '@components';
// Pages
import {
  LoginComponent,
  HomeComponent,
  HomeNewComponent,
  SinglePostComponent,
  SingleEventosComponent,
  SinglePageComponent,
  SingleContentComponent,
  GaleriasPageComponent,
  GaleriaVideosPageComponent,
  DocumentosPageComponent,
  SingleDocumentosPageComponent,
  ContactanosPageComponent,
  ConozcaAbastibleComponent,
  HacerLasCosasBienComponent,
  BeneficiosAbastibleComponent,
  BeneficiosNewAbastibleComponent,
  NoticiasAbastibleComponent,
  PuntoPrensaAbastibleComponent,
  ConcursosInternosAbastibleComponent,
  LinksAbastibleComponent,
  IndicadoresAbastibleComponent,
  CanalDenunciasAbastibleComponent,
  ManualMarcaComponent,
  Office365Component,
  MasAbastibleComponent,
  ComunicadosAbastibleComponent,
  DesarrolloOrganizacionalComponent,
  GobiernoCorporativoComponent,
  InnovacionComponent,
  IdeasDesafios1Component,
  IdeasDesafios2Component,
  IdeasDesafios3Component,
  IdeasDesafios4Component,
  IdeasDesafios5Component,
  ConozcaAbastible2Component,
  PerfilPageComponent,
  CumpleanosPageComponent,
  ListaGruposComponent,
  SingleGrupoComponent,
  SingleGaleriaComponent,
  EmbajadoresComponent,
  EventosComponent,
  SeguridadComponent,
  GaleriaVideosSectionPageComponent,
  GaleriaImageSectionPageComponent,
  DesafiosAbastibleComponent,
  ComunidadBuenasPracticasComponent,
  DocumentosCBPComponent,
  GaleriaVideosSectionInowPageComponent,
  InstructivosCoronavirusComponent,
  NoticiasGruposComponent,
  EmbajadoresInowComponent,
  EmbajadoresServicioComponent,
  PotencialAbastibleComponent,
  ComiteParitarioComponent,
  SearchGruposComponent,
  GaleriaGruposPageComponent,
  FormularioDesafio6Component,
  FormularioDesafio7InowComponent,
  IdeasDesafios6Component,
  IdeasDesafios7Component,
  SostenibilidadComponent,
  JuegoRiesgoComponent,
  TechoComponent,
  EstandarSenaliticaomponent,
  ConcursoAbastibleComponent,
  RevistaComponent,
  VideoInduccionPageComponent,
  ComiteParitarioDeHigieneYSeguridadOficinaCentral,
  PropositoAbastibleComponent,
  RespositorioLegalComponent,
  RedirectHomeComponent,
  FormulariosModuloMMComponent,
  DuenosDeProcesosComponent,
  RedesSocialesComponent,
  MetodologiaGoComponent,
  EmprendimientosComponent,
  TriviaCiberseguridad1AbastibleComponent,
  TriviaCiberseguridad2AbastibleComponent,
  TriviaCiberseguridad3AbastibleComponent,
  AgilidadAbastibleComponent,
  ReciclarEsColaborarAbastibleComponent,
  LeyKarinAbastibleComponent,
  NoticiasConexionNaranjaAbastibleComponent,
  NoticiasConexionNaranjaEdicionAbastibleComponent,
  NoticiasConexionNaranjaSingleAbastibleComponent,
  RepositorioImagenesAbastibleComponent,
  RepositorioImagenesCategoriaAbastibleComponent,
  TriviaEnemigoSecretoAbastibleComponent,
  ModeloHibridoAbastibleComponent,
} from '@pages';
import { CommonModule } from '@angular/common';
import { ImgMagnifier } from "ng-img-magnifier";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HomeNewComponent,
    HeaderComponent,
    HeaderNewComponent,
    FooterComponent,
    SidebarComponent,
    LoadingComponent,
    SinglePostComponent,
    SinglePageComponent,
    BaseLayout,
    SingleContentComponent,
    SingleEventosComponent,
    InternLayout,
    GaleriasPageComponent,
    GaleriaVideosPageComponent,
    DocumentosPageComponent,
    SingleDocumentosPageComponent,
    ContactanosPageComponent,
    ConozcaAbastibleComponent,
    HacerLasCosasBienComponent,
    BeneficiosAbastibleComponent,
    BeneficiosNewAbastibleComponent,
    DesarrolloOrganizacionalComponent,
    GobiernoCorporativoComponent,
    InnovacionComponent,
    IdeasDesafios1Component,
    IdeasDesafios2Component,
    IdeasDesafios3Component,
    IdeasDesafios4Component,
    IdeasDesafios5Component,
    PuntoPrensaAbastibleComponent,
    NoticiasAbastibleComponent,
    ConcursosInternosAbastibleComponent,
    LinksAbastibleComponent,
    IndicadoresAbastibleComponent,
    CanalDenunciasAbastibleComponent,
    ManualMarcaComponent,
    Office365Component,
    MasAbastibleComponent,
    ComunicadosAbastibleComponent,
    ConozcaAbastible2Component,
    PerfilPageComponent,
    CumpleanosPageComponent,
    ListaGruposComponent,
    SingleGrupoComponent,
    SingleGaleriaComponent,
    EmbajadoresComponent,
    EventosComponent,
    SeguridadComponent,
    GaleriaVideosSectionPageComponent,
    GaleriaImageSectionPageComponent,
    DesafiosAbastibleComponent,
    ComunidadBuenasPracticasComponent,
    DocumentosCBPComponent,
    GaleriaVideosSectionInowPageComponent,
    InstructivosCoronavirusComponent,
    NoticiasGruposComponent,
    EmbajadoresInowComponent,
    EmbajadoresServicioComponent,
    PotencialAbastibleComponent,
    ComiteParitarioComponent,
    SearchGruposComponent,
    FormularioDesafio6Component,
    FormularioDesafio7InowComponent,
    GaleriaGruposPageComponent,
    IdeasDesafios6Component,
    IdeasDesafios7Component,
    SostenibilidadComponent,
    JuegoRiesgoComponent,
    TechoComponent,
    ConcursoAbastibleComponent,
    VideoInduccionPageComponent,
    EstandarSenaliticaomponent,
    RevistaComponent,
    ComiteParitarioDeHigieneYSeguridadOficinaCentral,
    PropositoAbastibleComponent,
    RespositorioLegalComponent,
    RedirectHomeComponent,
    FormulariosModuloMMComponent,
    DuenosDeProcesosComponent,
    RedesSocialesComponent,
    MetodologiaGoComponent,
    EmprendimientosComponent,
    TriviaCiberseguridad1AbastibleComponent,
    TriviaCiberseguridad2AbastibleComponent,
    TriviaCiberseguridad3AbastibleComponent,
    AgilidadAbastibleComponent,
    ReciclarEsColaborarAbastibleComponent,
    LeyKarinAbastibleComponent,
    NoticiasConexionNaranjaAbastibleComponent,
    NoticiasConexionNaranjaEdicionAbastibleComponent,
    NoticiasConexionNaranjaSingleAbastibleComponent,
    RepositorioImagenesAbastibleComponent,
    RepositorioImagenesCategoriaAbastibleComponent,
    TriviaEnemigoSecretoAbastibleComponent,
    ModeloHibridoAbastibleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    Ng2SearchPipeModule,
    NgImageSliderModule,
    PickerModule,
    MentionModule,
    CommonModule,
    ImgMagnifier
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    HttpClientModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
